::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #fb8500;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25),inset -2px -2px 2px rgba(0,0,0,.25);
}
::-webkit-scrollbar-track {
  background: linear-gradient(90deg,#434343,#434343 1px,#111 0,#111);
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* Sidebar Css */
.app-container {
  display: flex;
  background-color: #f0f3fb;
  height: 100vh;
  overflow: hidden;
  font-size: 12px;
}
.app {
   width: 250px;
}
.content {
  flex: 2;
  overflow: auto;
}
.css-dip3t8 {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: #1d3557 !important;
}
.css-ewdv3l {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #1d3557 !important;
}

.css-16jesut >.ps-menu-button:hover {
  background-color: #f0f3fb !important;
}
.css-1t8x7v1 >.ps-menu-button:hover {
  background-color: #f0f3fb !important;
}
.css-1tqrhto >.ps-menu-button:hover {
  background-color: #f0f3fb !important;
}
/* End Sidebar Css */
.scrollOnXaxis{
  max-width: 100%;
  overflow-x: auto;
}
/* Navbar Styles */
.dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  left: auto;
  margin-top: var(--bs-dropdown-spacer);
}

.enabled-badge {
  pointer-events:visible;
  opacity: 1; 
}
.disabled-badge {
  pointer-events: none;
  opacity: 0.5; 
}

.badge-warning-lighten {
  color: #f9bc0d;
  background-color: #fef8e7;
  transition: background-color 0.3s, color 0.3s;
}
.badge-warning-lighten:hover {
  color: #000000; 
  background-color: #FFD700; 
  cursor: pointer;
}

.badge-danger-lighten {
  color: #fa6767;
  background-color: #fff0f0;
  transition: background-color 0.3s, color 0.3s;
}
.badge-danger-lighten:hover {
  color: #000000; 
  background-color: #fa6767; 
  cursor: pointer;
}

.badge-green-lighten {
  color: #fff;
  background-color: #4CAF50;
  transition: background-color 0.3s, color 0.3s;
}
.badge-green-lighten:hover {
  color: #000000; 
  background-color: #C2E3C3; 
  cursor: pointer;
}

.badge-orange-lighten {
  color: #fd7e14;
  border: 1px solid #fd7e14;
  transition: background-color 0.3s, color 0.3s;
}
.badge-orange-lighten:hover {
  color: #000000; 
  background-color: #FFDCBE; 
  cursor: pointer;
}

.badge-yellow-lighten {
  color: #f9bc0d;
  border: 1px solid #f9bc0d;
  transition: background-color 0.3s, color 0.3s;
}
.badge-yellow-lighten:hover {
  color: #000000; 
  background-color: #fef8e7;
  cursor: pointer;
}

.badge-info-lighten {
  color: #44badc;
  background-color: #ecf8fc;
  transition: background-color 0.3s, color 0.3s;
}
.badge-info-lighten:hover {
  color: #000000; 
  background-color: #44badc; 
  cursor: pointer;
}

.badge-purple-lighten {
  color: #fff;
  background-color: #6f42c1;
  transition: background-color 0.3s, color 0.3s;
}
.badge-purple-lighten:hover {
  color: #000000; 
  background-color: #8884d8; 
  cursor: pointer;
}

.badge-success-lighten {
  background-color: #ecfbf5;
  color: #42d29d;
  transition: background-color .3s,color .3s;
}
.badge-success-lighten:hover {
  background-color: #42d29d;
  color: #000;
  cursor: pointer;
}

.error-message {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  background-color: #ed3c0d; 
  color: #ffffff; 
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

#recordCount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 5px;
  color: #333;
  font-weight: 700;
}

.disabled-row {
  pointer-events: none;  
  opacity: 0.5;          
  background-color: #f0f0f0;
}

/* Breadcrumbs.css */
.breadcrumb {
  font-size: 20px;
  padding: 8px 15px;
  border-radius: 4px;
}

.breadcrumb-item {
  display: inline-block;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #444;
  font-weight: 500;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.narrowFormats{
  color: #717b9e !important;
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
  padding-right: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.profile-image {
  object-fit: cover; 
  width: 35px;       
  height: 35px;     
  border: 2px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}



















