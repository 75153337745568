.options-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.option-card {
    padding: 15px;
    margin-bottom: 8px;
    border-radius: 10px;
    font-size: 18px;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px; 
    width: 100%;

}

.option-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.option-card.correct {
    background-color: #e0f7e0;
    border-left: 5px solid #2d7a2d;
    color: #2d7a2d;
}

.option-text {
    display: inline-block;
    width: 100%;
    padding-left: 10px;
}

.no-border-quill {
    border: none;
    padding: 20px;
    font-size: 20px;
    line-height: 1.5;
}

.action-button {
    transition: background-color 0.3s ease;
}

.action-button:hover {
    background-color: #5c6bc0;
}

.breadcrumb-item {
    font-size: 16px;
    font-weight: 500;
}

.breadcrumb-item.active {
    color: #5c6bc0;
}

.current-question {
    background-color: #ffeb3b; /* Yellow for current question */
    border: 2px solid #ff9800;
}
.skipped-question {
    background-color: #ffcccb; /* Reddish for skipped */
    border: 2px solid #f44336;
}
.review-question {
    background-color: #cce5ff; /* Bluish for marked for review */
    border: 2px solid #2196f3;
}
.saved-question {
    background-color: #c8e6c9; /* Greenish for saved */
    border: 2px solid #4caf50;
}

