body {
    margin: 0;
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: hsl(0, 0%, 100%);
}

@media (min-width: 768px) {
  .navbar-toggler{
    display: none;
  }
}
.menu1 {
  margin-bottom: 5px;
  margin-top: 0px;
}
.text-item {
  color: #e0e1dd; 
}
.sidebar-item {
  color: #e0e1dd; 
}
.sidebar-item .icon {
  fill: #e0e1dd;
}

.text-item:hover {
  background-color: #f3f3f3;
}
.text-item:hover .span-item {
  color: #000 !important;
}
.text-item:hover .icon-item {
  fill: #000 !important;
}


/* Hover effect for background */
.sidebar-item:hover {
  background-color: #f3f3f3;
}
.sidebar-item:hover .text {
  color: black; 
}
.sidebar-item:hover .icon {
  fill: black;
}

.subMenuActive {
  background-color: #f0f3fb;
}

.activeTab {
  display: inline-block;
  font-weight: 700;
  color: #e0e1dd;
}

.activeTab::after {
  content: '\F285';
  font-family: "bootstrap-icons"; 
  position: absolute;
  bottom: 35%;
  left: 90%; 
  color: #fd7e14;
  font-size: 10px;
  font-weight: 700;
}

.activeTab:hover {
  background-color: #f0f3fb;
  color: black; 
  fill: black;
}

.css-1cuxlhl {
  color: #e0e1dd !important;
}
.css-1cuxlhl:hover {
  color: black !important;
}

.no-border-quill .ql-editor {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}
.no-border-quill .ql-container {
  border: none !important;
}








  
