.loginContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg, #f0f8ff, #e6efff);
  overflow: hidden;
}

.loginContainer img {
  position: absolute;  
  bottom: 0%; 
  left: 0%; 
  transform: translateX(-30%);
  max-height: 100vh;  
}

.orgName {
  font-size: 3rem; 
  font-weight: bold;
  color: #2a4d69;
  text-shadow: 0 0 5px #9dbcdc, 0 0 5px #9dbcdc;
  margin-bottom: 2px;
  transform: translateY(25px);
  animation: fadeInUp 1s ease-in-out;
}

.orgName span {
  font-weight: lighter;
}

.orgName strong {
  font-weight: bolder;
}

.orgName img {
  position: absolute;  
  top: -60%; 
  left: 85%; 
  max-height: 85px;  
  margin-left: 10px;
}

.tagline {
  color: #5a7ea3;
  font-size: 1rem; 
  margin-bottom: 40px;
  text-shadow: 0px 0px 5px #9dbcdc;
  transform: translateY(20px);
  animation: fadeInUp 1.5s ease-out;
}

.loginBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transform: perspective(500px) translateZ(0px);
  animation: fadeInUp 2s ease-out;
}

.title {
  color: #2a4d69;
  font-size: 2rem;
  margin-bottom: 25px;
}

.inputField {
  margin: 10px 0;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: #ffffff;
  color: #2a4d69;
  font-size: 1.1rem; 
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  mix-blend-mode: multiply;
}

.loginButton {
  padding: 14px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  border: none;
  background-color: #5a7ea3;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem; 
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba((90, 126, 163, 0.4));
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  mix-blend-mode: multiply;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.loginButton:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 15px rgba((90, 126, 163, 0.6));
}

.lottieAnimation {
  position: absolute;
  bottom: 7.8%;
  right: 7.8%;
  transform: translateX(0) scale(1);
  width: 200px;
  height: 200px;
  z-index: -1;
  mix-blend-mode: multiply;
  opacity: 2;
}

@keyframes pulse {
  0% { transform: translateX(-50%) scale(1.3); }
  50% { transform: translateX(-50%) scale(1.2); }
  100% { transform: translateX(-50%) scale(1.3); }
}

.floating-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: 
    url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK8JTZ7tAonKaKoJvqa_ahxgQFgsd0uxrm6wjyD33zSdIQtb-aI5zm2vezqBTKvX5HrQ0&usqp=CAU');
  background-repeat: repeat;
  background-size: 500px;
  opacity: 0.05;
  animation: twinkle 3s infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.05; }
  25% { opacity: 0.1; }
  50% { opacity: 0.2; }
  75% { opacity: 0.1; }
}

.role-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0px; 
  position: relative;
}

.role-button {
  background: linear-gradient(45deg, #dbeafe, #a7c7e7);
  color: #2a4d69;
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2rem;
}

.role-button.active {
  background: linear-gradient(45deg, #5a7ea3, #2a4d69);
  color: #fff;
}

.role-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgba(90, 126, 163, 0.5);
}

.inputField {
  margin-bottom: 20px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
